import { Upload, message } from "antd";
import { useEffect, useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

// Scroll To Top
export const scrollTop = () => {
  window.scrollTo(0, 0);
};

// Ant-d Form Password Validator
export const validatePassword = (form) => (_, value) => {
  const { getFieldValue } = form;
  if (value && value !== getFieldValue("password")) {
    return Promise.reject("Confirm password do not match");
  }
  return Promise.resolve();
};

// Get Screen Width
export const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(window?.innerWidth);
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return screenWidth;
};

// Ant-d Form Password Validator
export const validateCheckbox = (form) => (_, value) => {
  if (value) {
    return Promise.resolve();
  }
  return Promise.reject("You must agree to the terms and conditions");
};

// Ant-d Form Validate Phone Number
export const validatePhone =
  (form, phoneNum, countryCode, setPhoneValidation) => (_, value) => {
    const formatted_phone = phoneNum?.substring(countryCode?.length);
    if (!formatted_phone.length) {
      setPhoneValidation(true);
      return Promise.reject("You cannot leave this field blank");
    } else if (
      formatted_phone &&
      formatted_phone.length > 1 &&
      formatted_phone.length < 8
    ) {
      setPhoneValidation(true);
      return Promise.reject("Phone number is not valid");
    }
    setPhoneValidation(false);
    return Promise.resolve();
  };

export const validateName = (_, value) => {
  if (!value) {
    return Promise.reject(new Error('You cannot leave this field blank'));
  } else if (/\d/.test(value)) {
    return Promise.reject(new Error('Numbers are not allowed.'));
  } else if (value.length < 3) {
    return Promise.reject(new Error('Should be 3 characters.'));
  } else if (value.length > 30) {
    return Promise.reject(new Error('Cannot be more than 30 characters.'));
  }
  return Promise.resolve();
};

export const validateEmail = (rule, value, callback) => {
  if(!value) {
    callback('You cannot leave this field blank');
    return
  }
  
  const trimmedValue = value.trim();
  
  if (!trimmedValue || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimmedValue)) {
    callback();
  } else {
    callback('Please enter a valid email address');
  }
};

// OnChange Phone Number
export const onChangePhone = (
  form,
  value,
  country,
  setCountryCode,
  setPhoneNum,
  setSlug
) => {
  setCountryCode(country.dialCode);
  setSlug(country.countryCode);
  setPhoneNum(value);
  form.validateFields(["phone"]);
};

// Langugae Switch for (Responses which are Rejected)
export const errMess = (result) => {
  // const { i18n } = useTranslation();
  const language = localStorage.getItem("i18nextLng") || {};
  if (language === "en") {
    return result?.error?.en;
  } else {
    return result?.error?.fr;
  }
};

// Langugae Switch for (Responses which are  Successful)
export const succMess = (result) => {
  // const { i18n } = useTranslation();
  const language = localStorage.getItem("i18nextLng") || {};
  if (language === "en") {
    return result?.data?.message?.en;
  } else {
    return result?.data?.message?.fr;
  }
};

// Clear Local Storage
export const clearLocalStorage = (navigate) => {
  localStorage.removeItem("userData");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("token");
  localStorage.removeItem("persist:root");
  localStorage.removeItem("__paypal_storage__");
  if (navigate) {
    setTimeout(() => {
      navigate("/login");
    }, 1000);
  }
};

const ImageUploader = ({ imageUrl, handleChange, form, page }) => {
  const [loadingImg, setLoadingImg] = useState(false);
  const multiImage = page == "addPainting" ? true : false;

  // Image Uploader
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result, img));
    reader.readAsDataURL(img);
  };

  // Before Image Upload
  const beforeUpload = (file) => {
    if (file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error("Image must be smaller than 5MB!");
      }
      return isJpgOrPng && isLt5M;
    }
  };
  // Handle Image Change
  const handleImageChange = (info) => {
    if (info.file && info.file.originFileObj instanceof Blob) {
      getBase64(info.file.originFileObj, (base64) => {
        setLoadingImg(false);
        handleChange(info.file.originFileObj, base64);
      });
    } else {
      setLoadingImg(false);
      handleChange(null, null);
    }
  };

  const uploadButton = (
    <div>
      {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}> Upload </div>
    </div>
  );

  return (
    <>
      <Upload
        name="image"
        listType="picture-circle"
        className="avatar-uploader"
        showUploadList={multiImage}
        multiple={multiImage}
        maxCount={multiImage ? 10 : 1}
        accept=".jpg, .jpeg, .png"
        beforeUpload={beforeUpload}
        onChange={handleImageChange}
        customRequest={() => {}}
      >
        {!multiImage && imageUrl ? (
          <img src={imageUrl} alt="image" style={{ width: "100%" }} />
        ) : (
          uploadButton
        )}
      </Upload>
    </>
  );
};

export { ImageUploader };